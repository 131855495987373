import RouteList from './Routes';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import project from '../package.json'
console.log("version", project.version)

function App() {

  return (
    <Router>
      <RouteList />
    </Router>
  );
}

export default App;
