import {
  Route, Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import NoMatch from "./pages/NoMatch";
import Login from './pages/Login';
import Contacts from './pages/Contacts';
import CallLogs from './pages/CallLogs';
import Layout from './Layout';
import Signup from './pages/Signup';
import Companies from './pages/Companies';
import { useEffect } from 'react';
import { encryptStorage } from './assets/js/encryptStorage';
import Users from './pages/Users';

const RouteList = () => {
  const location = useLocation().pathname;
  const nav = useNavigate();
  
  useEffect(() => {
    const telemartToken = encryptStorage.getItem("telemartToken");
    const telemartRole = encryptStorage.getItem("telemartRole");
  
    if (!telemartToken || !telemartRole) {
      // User does not have token or role, can only access "/" and "/signup"
      if (location !== "/" && location !== "/signup") {
        nav("/");
      }
    } 
    else {
      // User has token and role
      if (location === "/" || location === "/signup") {
        if (telemartRole !== "super_admin") {
          nav("/Companies");
        } 
        else {
          nav("/admin/users");
        }
      } 
      else if (telemartRole === "super_admin") {
        // Admin can only access "/", "/signup", and "/admin/users"
        if (location !== "/admin/users" && location !== "/" && location !== "/signup") {
          nav("/admin/users");
        }
      } 
      else {
        if (location.includes("/admin")) {
          nav("/Companies");
        }
      }
    }
  }, [location, nav]);
  
  

  return (
    <Routes>
      
      <Route path="/" element={(<Login />)} />
      <Route path="/signup" element={(<Signup />)} />
      <Route path="/admin/users" element={(<Users />)} />

      <Route path="*" element={<NoMatch />} />

      <Route path="/" element={<Layout />}>
        <Route path="/companies" element={<Companies />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/calllogs" element={<CallLogs />} />
      </Route>

    </Routes>
  )
}

export default RouteList