import {
    createSlice,
} from '@reduxjs/toolkit'


const initialState = {
    user: "",
    client: null,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.user = true
        },
        logoutUser: (state) => {
            state.user = false
            state.client = null
            state.sloif_ids = []
            // connectOpeniap()
        }
    },
    extraReducers: (builder) => {
    }
})

export const { loginUser, logoutUser } = userSlice.actions
export default userSlice.reducer