/* eslint-disable no-unused-vars */
import { Disclosure } from "@headlessui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { BiExit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { siderBarItems } from "../static/index";
import { useDispatch } from "react-redux";
import { logoutUser } from "../store/slices/userSlice";
import Logo from '../assets/images/telemart.png'
import { encryptStorage } from "../assets/js/encryptStorage";
import { removeSelectedCompanyId } from "../store/slices/companySlice";

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  // const dispatch = useDispatch();

  const items = siderBarItems();
  const handleLogout = async () => {
    dispatch(logoutUser())
    dispatch(removeSelectedCompanyId())
    encryptStorage.removeItem("telemartToken")
    navigate("/")
  };

  return (
    <div className="grid grid-cols-[minmax(0,1fr) auto] h-full">
      <Disclosure as="nav">
        <Disclosure.Button className="lg:hidden absolute left-4 inline-flex items-start peer justify-content rounded-md p-2 text-gray-900  focus:outline-none focus:ring-2 focus:ring-inset focus:rind-white group">
          <GiHamburgerMenu className=" h-9 w-6" aria-hidden="true" />
        </Disclosure.Button>

        <div className="p-4 h-full bg-white z-20 fixed lg:static top-0 -left-96 lg:left-0 peer-focus:left-0 peer:transition ease-out delay-150 duration-200">
          <div className="flex  justify-start items-center cursor-pointer mt-[0rem]" onClick={() => navigate("/companies")}>
            <img src={Logo} alt="" className="h-[7rem] ml-[-1rem]" />
            <p className="text-xl font-bold ml-[-1rem]"><span className="text-blue-500">Tele</span>market</p>
          </div>
          <div className="mt-0 my-4 pb-4">
            {items?.map((item, index) => (
              <label key={index}>
                <input readOnly type="radio" className="peer sr-only" name="visualize" checked={pathname.includes(item.path) ? true : false} />
                <div className="flex text-sm mb-2 p-2 justify-start items-center gap-2 bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-700 hover:border-transparent rounded cursor-pointer"
                  onClick={() => {
                    navigate(item.path);
                    if (item.segment === true) {
                      // dispatch(getSegmexnts_v1("positive"));
                    } else {
                    }
                  }}
                >
                  {item.icon}
                  <h3 className="text-sm font-semibold group-hover:text-white">{item.title}</h3>
                </div>
              </label>
            ))}

            <br></br>

            <div className="border-2 mb-2"></div>

            <div className="flex mb-2 justify-start items-center gap-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-700 hover:border-transparent rounded cursor-pointer" onClick={handleLogout}>
              <BiExit className="h-6 w-6 group-hover:text-white" aria-hidden="true" />
              <h3 className="text-sm font-semibold group-hover:text-white">Logout</h3>
            </div>
          </div>
        </div>
      </Disclosure>
    </div>
  );
}

export default Sidebar;
