/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import MetaData from "../../components/MetaData";
import Loader from "../../components/Loader";
import ReactDataTable from "../../components/ReactDataTable";
import tailwindClasses from "../../assets/tailwindClasses";
import axios from "axios";
import { makeUrl } from "../../assets/js/buildURL";
import CustomButton from "../../components/Reusable/Button/CustomButton";
import { encryptStorage } from "../../assets/js/encryptStorage";
import { capitalize } from "@mui/material";

const { componentMainDiv, componentHeaderDiv, componentHeaderFlexDiv } = tailwindClasses;

const AllUser = () => {
  const navigate = useNavigate();
  const reduxCompanyId = useSelector(state => state.companyReducer.selectedCompanyId)

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const [modalData, setModalData] = useState({ text: "", headding: "" })
  const [showModal, setShowModal] = useState(false);

  const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };

  const columns = [
    {
      name: "S No.",
      selector: (row, index) => index + 1,
      width: "80px",
      sortable: true,
    },
    {
      name: "cid",
      selector: (row) => row?.contact_id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Vapi Call Status",
      selector: (row) => row?.vapi_call_status || "-",
      sortable: true,
      wrap: true,
      width: "140px"
    },
    {
      name: "Status",
      selector: (row) => row?.status || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Transcript",
      selector: (row) => row.transcript != null ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(row.transcript, "transcript")} /> : "-",
      sortable: true,
      wrap: true,
      width: "120px"
    },
    {
      name: "Summary",
      selector: (row) => row.summary != null ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(row.summary, "summary")} /> : "-",
      sortable: true,
      wrap: true,
      width: "120px"
    },
    {
      name: "Customer Response",
      selector: (row) => row?.customer_response || "-",
      sortable: true,
      wrap: true,
      width: "170px"
    },
    {
      name: "Call End Reason",
      selector: (row) => row?.call_end_reason || "-",
      sortable: true,
      wrap: true,
      width: "144px"
    },
    {
      name: "Call End Time",
      selector: (row) => row?.call_end_time || "-",
      sortable: true,
      wrap: true,
      width: "130px"
    },

    {
      name: "Last Modified At",
      selector: (row) => row?.updated_at || "-",
      sortable: true,
      wrap: true,
      width: "150px"
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at || "-",
      sortable: true,
      wrap: true,
      width: "120px"
    },
    {
      name: "Call Start Data",
      selector: (row) => (row.call_start_data != null) ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(JSON.stringify(row?.call_start_data), "Call Start Data")} /> : "-",
      sortable: true,
      wrap: true,
      width: "150px"
    },
    {
      name: "Call End Data",
      selector: (row) => (row.call_end_data != null) ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(JSON.stringify(row?.call_end_data), "Call End Data")} /> : "-",
      sortable: true,
      wrap: true,
      width: "150px"
    }
  ];

  const handleClick = (text, headding) => {
    setModalData({ text: text, headding: headding })
    setShowModal(true)
  }

  const getAllCallLogs = async () => {
    try {
      const URL = makeUrl("data", `getCallLogs/${reduxCompanyId}`)
      const res = await axios.get(URL, { headers })
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`)
      }
      const data = res.data.data
      setData(data);
    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`)
      console.error("getAllCallLogs Error: ", e.message);
    }
    setLoading(false)
  };

  const deleteData = async () => {
    setLoading(true)
    try {
      const URL = makeUrl("data", "deleteCallLogs")
      const res = await axios.get(URL, { headers })
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`)
      }
      toast.success("Successfully deleted")
      setRefresh(!refresh)

    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`)
      console.error("deteleData Error: ", e.message);
    }
    setLoading(false)
  };

  const closeModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    getAllCallLogs();
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [refresh, reduxCompanyId]);

  return (
    <div className="min-h-[89%]">
      <MetaData title={"Call Logs"} />

      <div className={componentMainDiv}>

        <div className={componentHeaderDiv}>
          <div className={componentHeaderFlexDiv}>
            <div className="text-2xl">All Call logs</div>
            <div>
              <button className={`text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded ${loading ? "opacity-50 cursor-not-allowed pointer-events-none" : ""}`} onClick={deleteData} loading={loading}>Delete Call Logs</button>
            </div>
          </div>
        </div>

        <div className="rounded-b-md">
          {
            loading ? <Loader /> :
              <ReactDataTable columns={columns} data={data} height="70vh" />
          }
        </div>
      </div>

      {
        showModal ? (
          <>
            <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
              <div className=" my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg  flex flex-col  bg-white outline-none focus:outline-none ">

                  <div className="flex flex-col items-center justify-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <label className="text-3xl font-semibold">{capitalize(modalData?.headding)}</label>
                  </div>

                  <div className="flex flex-col items-start p-5 border-b border-solid border-blueGray-200 rounded-t max-h-80 overflow-y-auto">
                    {modalData?.text}
                  </div>

                  <div className=" flex-row justify-around m-4">
                    <CustomButton label={"Close"} onClick={closeModal} classname={"bg-red-500 hover:bg-red-600 block w-[100%] mt-2 text-base"} />
                  </div>

                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null
      }
    </div>
  );
};
export default AllUser;
