const config_dev = {
    BASE_URL: "http://localhost:5000/api",
    ENVIRONMENT: "dev",

    // Other
    encryption_key: "telemarketing_secret_key_v1",
}

const config_staging = {
    BASE_URL: "http://localhost:5000/api",
    ENVIRONMENT: "staging",

    // Other
    encryption_key: "telemarketing_secret_key_v1",
}

const config_prod = {
    BASE_URL: "https://telemarket.eusopht.com/apis/api",
    ENVIRONMENT: "prod",

    // Other
    encryption_key: "telemarketing_secret_key_v1",
}

let config = config_dev
config = config_staging
config = config_prod

export default config